import { Action } from 'redux';

export const FEATURE_TOGGLES_POLLING_TIME_MS = 600000; // 10 minutes
export const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';

export interface IBrandDetails {
    logoPrefix: string;
    signUpUrl: string;
    signUpPrefix: string;
    signUpText: string;
}

export interface IFeatureToggleProviderState {
}

export interface IFeatureToggleProviderStateProps {
    featureToggles: IFeatureToggle[];
    userId: string;
}

export interface IFeatureToggleProviderReducer {
    toggles: IFeatureToggle[];
}

export interface IFeatureToggleProviderDispatchProps {
    setFeatureToggles: (toggles: IFeatureToggle[]) => void;
}

export interface IFeatureToggleProviderProps extends IFeatureToggleProviderDispatchProps, IFeatureToggleProviderStateProps {
    children?: any;
}

export interface IFeatureToggle {
    feature: string;
    enabled: boolean;
    value: string | boolean | number | object;
}

export interface ISetFeatureTogglesAction extends Action {
    type: typeof SET_FEATURE_TOGGLES;
    toggles: IFeatureToggle[];
}

export type IFeatureToggleProviderActionTypes = ISetFeatureTogglesAction;

