import {
    FLIP_GROUPS,
    IFlipGroupsAction,
    IPartiesActionTypes,
    IPartiesReducer,
    IParty,
    IPartyVariantType,
    ISetContractScrollToPartyAction,
    ISetDraggedPartyAction,
    ISetExpandedGroupAction,
    ISetPartiesAction,
    ISetScrollToPartyAction,
    RESET_SELECTIONS,
    SET_CONTRACT_SCROLL_TO_PARTY,
    SET_DRAGGED_PARTY,
    SET_EXPANDED_GROUP,
    SET_HIGHLIGHTING,
    SET_PARTIES,
    SET_SCROLL_TO_PARTY,
} from './Parties-types';
import {
    ADD_PARTY,
    IAddPartyAction,
    IRemovePartyAction,
    ISetSelectedPartyAction,
    IUpdatePartyAction,
    REMOVE_PARTY,
    SET_SELECTED_PARTY,
    UPDATE_PARTY,
} from '../Party/Party-types';
import {
    ISetHighlightedPartyAction,
    ISetPartyToAddAction,
    SET_HIGHLIGHTED_PARTY,
    SET_PARTY_TO_ADD,
} from '../Paragraph/Paragraph-types';
import { alphabetizeParties, reIndex } from './Parties-helpers';

const initialState: IPartiesReducer = {
    contractScrollToParty: null,
    draggedParty: null,
    expandedGroup: null,
    highlightedParty: null,
    highlighting: false,
    parties: [],
    partyToAdd: '',
    scrollToParty: null,
    selectedParty: null,
};

const PartiesReducer =
    (state = initialState, action: IPartiesActionTypes): IPartiesReducer => {
        let parties: IParty[];
        let party: IParty;

        switch (action.type) {
            case ADD_PARTY:
                parties = [ ...state.parties];
                const name =  (action as IAddPartyAction).partyToAdd;
                const type =  (action as IAddPartyAction).partyType;
                const id = parties.length;

                if (name) {
                    party = {
                        id,
                        name,
                        type,
                        originalType: null,
                        variantType: IPartyVariantType.REFERENCE,
                    };

                    parties.push(party);
                }

                parties = parties.sort(alphabetizeParties).map(reIndex);

                return {
                    ...state,
                    parties,
                    selectedParty: party,
                    scrollToParty: party,
                    partyToAdd: null,
                };
            case FLIP_GROUPS:
                const { typeA, typeB } = action as IFlipGroupsAction;

                parties = [ ...state.parties ].map((party) => {
                    if (party.type === typeA) {
                        party = { ...party, type: typeB };
                    } else if (party.type === typeB) {
                        party = { ...party, type: typeA };
                    }
                    return party;
                });

                return {
                    ...state,
                    parties,
                };
            case REMOVE_PARTY:
                const partyToRemove = (action as IRemovePartyAction).party;

                parties = [ ...state.parties].filter(party => party.id !== partyToRemove.id);
                parties = parties.sort(alphabetizeParties).map(reIndex);

                return {
                    ...state,
                    parties,
                };
            case RESET_SELECTIONS:
                return {
                    ...state,
                    draggedParty: null,
                    expandedGroup: null,
                    highlightedParty: null,
                    partyToAdd: null,
                    selectedParty: null,
                };
            case SET_EXPANDED_GROUP:
                return {
                    ...state,
                    expandedGroup: (action as ISetExpandedGroupAction).expandedGroup,
                };
            case SET_CONTRACT_SCROLL_TO_PARTY:
                return {
                    ...state,
                    contractScrollToParty: (action as ISetContractScrollToPartyAction).party,
                };
            case SET_DRAGGED_PARTY:
                return {
                    ...state,
                    draggedParty: (action as ISetDraggedPartyAction).party,
                };
            case SET_HIGHLIGHTED_PARTY:
                return {
                    ...state,
                    highlightedParty: (action as ISetHighlightedPartyAction).highlightedParty,
                };
            case SET_PARTIES:
                parties = (action as ISetPartiesAction).parties;

                return {
                    ...state,
                    parties,
                };
            case SET_PARTY_TO_ADD:
                return {
                    ...state,
                    partyToAdd: (action as ISetPartyToAddAction).partyToAdd,
                };
            case SET_SCROLL_TO_PARTY:
                return {
                    ...state,
                    scrollToParty: (action as ISetScrollToPartyAction).party,
                };
            case SET_SELECTED_PARTY:
                return {
                    ...state,
                    selectedParty: (action as ISetSelectedPartyAction).selectedParty,
                };
            case UPDATE_PARTY:
                const updatedParty = (action as IUpdatePartyAction).party;
                parties = [ ...state.parties ].map((party) => {
                    if (party.id === updatedParty.id) {
                        party = updatedParty;
                    }
                    return party;
                });

                return {
                    ...state,
                    parties,
                };
            case SET_HIGHLIGHTING:
                return {
                    ...state,
                    highlighting: action.highlighting,
            };
            default:
                return state;
        }
    };

export default PartiesReducer;
