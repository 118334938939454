import { Action } from 'redux';

import { IParagraph } from 'types/thoughtriver';
import { IParty } from '../Parties/Parties-types';

export const SET_ACCOUNT_CODE = 'SET_ACCOUNT_CODE';
export const SET_API_URL = 'SET_API_URL';
export const SET_APP_STATUS = 'SET_APP_STATUS';
export const SET_AUTH_DETAILS = 'SET_AUTH_DETAILS';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_CONTRACT_ID = 'SET_CONTRACT_ID';
export const SET_MESSAGE_ID = 'SET_MESSAGE_ID';
export const SET_REPLY_TO = 'SET_REPLY_TO';
export const SET_SERVICE_EMAIL = 'SET_SERVICE_EMAIL';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_VERSION_ID = 'SET_VERSION_ID';
export const SET_VERSION_NUMBER = 'SET_VERSION_NUMBER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export const AUTH_GATEWAY_CREDENTIALS = 'client-credentials';
export const AUTH_GATEWAY_PASSWORD = 'password';

export interface IAppStateProps {
    accountCode: string;
    apiUrl: string;
    authToken: string;
    contractId: string;
    contractName: string;
    messageId: string;
    replyTo: string;
    serviceEmail: string;
    serviceType: IServiceType;
    status: IAppStatus;
    statusMessage: string;
    username: string;
    versionId: string;
    tokenExp: number;
    analyticsOptOut: boolean;
}

export enum IAppStatus {
    COMPLETED = 'completed',
    ERROR = 'error',
    LOADED = 'loaded',
    LOADING = 'loading',
    LOGIN_REQUIRED = 'login_required',
}

export enum IServiceType {
    FLOW = 'flow',
    WORD = 'word',
}

export interface IUserDetails {
    firstName: string;
    id: string;
    lastName: string;
    email: string;
    roles: string[];
    tier: string;
}

export interface IAppReducer {
    accountCode: string;
    apiUrl: string;
    authToken?: string;
    contractId: string;
    messageId: string;
    replyTo: string;
    serviceEmail: string;
    serviceType: IServiceType;
    status: IAppStatus;
    statusMessage: string;
    stream: string;
    username: string;
    versionId: string;
    versionNumber: number;
    tokenExp: number;
    analyticsOptOut: boolean;
    user: IUserDetails;
    sessionKey?: string;
}

export interface IAppDispatchProps {
    resetSelections: () => void;
    setAppStatus: (status: IAppStatus, statusMessage?: string) => void;
    setAuthDetails: (authDetails: IAuthDetails) => void;
    setAnalyticsInitialised: () => void;
    setContractId: (contractId: string) => void;
    setContractName: (name: string) => void;
    setDraggedParty: (party: IParty) => void;
    setHighlighting: (highlighting: boolean) => void;
    setMessageId: (messageId: string) => void;
    setParagraphs: (paragraphs: IParagraph[]) => void;
    setParties: (parties: IParty[]) => void;
    setReplyTo: (replyTo: string) => void;
    setServiceEmail: (serviceEmail: string) => void;
    setServiceType: (serviceType: IServiceType) => void;
    setVersionId: (versionId: string) => void;
    setVersionNumber: (versionNumber: number) => void;
    setUserDetails: (user: IUserDetails) => void;
}

export interface IAppProps extends IAppDispatchProps, IAppStateProps {

}

export interface ISetContractIdAction extends Action {
    type: typeof SET_CONTRACT_ID;
    contractId: string;
}

export interface ISetVersionIdAction extends Action {
    type: typeof SET_VERSION_ID;
    versionId: string;
}

export interface ISetVersionNumberAction extends Action {
    type: typeof SET_VERSION_NUMBER;
    versionNumber: number;
}

export interface ISetAuthTokenAction extends Action {
    type: typeof SET_AUTH_TOKEN;
    authToken: string;
}

export interface ISetApiUrlAction extends Action {
    type: typeof SET_API_URL;
    apiUrl: string;
}

export interface ISetAccountCodeAction extends Action {
    type: typeof SET_ACCOUNT_CODE;
    accountCode: string;
}

export interface ISetUsernameAction extends Action {
    type: typeof SET_USERNAME;
    username: string;
}

export interface ISetMessageIdAction extends Action {
    type: typeof SET_MESSAGE_ID;
    messageId: string;
}

export interface ISetReplyToAction extends Action {
    type: typeof SET_REPLY_TO;
    replyTo: string;
}

export interface ISetServiceEmailAction extends Action {
    type: typeof SET_SERVICE_EMAIL;
    serviceEmail: string;
}

export interface ISetServiceTypeAction extends Action {
    type: typeof SET_SERVICE_TYPE;
    serviceType: string;
}

export interface ISetAppStatusAction extends Action {
    type: typeof SET_APP_STATUS;
    status: IAppStatus;
    statusMessage: string;
}

export interface ISetAuthDetailsAction extends Action {
    type: typeof SET_AUTH_DETAILS;
    accountCode: string;
    apiUrl: string;
    authToken: string;
    username: string;
    tokenExp: number;
    analyticsOptOut: boolean;
    sessionKey?: string
    streamCode?: string;
}

export interface ISetUserDetailsAction extends Action {
    type: typeof SET_USER_DETAILS;
    firstName: string;
    id: string;
    lastName: string;
    email: string;
    roles: string[];
    tier: string;
}

export type IAppActionTypes =  ISetContractIdAction |
    ISetVersionIdAction |
    ISetVersionNumberAction |
    ISetAuthTokenAction |
    ISetApiUrlAction |
    ISetAccountCodeAction |
    ISetUsernameAction |
    ISetAppStatusAction |
    ISetAuthDetailsAction |
    ISetReplyToAction |
    ISetMessageIdAction |
    ISetServiceEmailAction |
    ISetServiceTypeAction |
    ISetUserDetailsAction;

export interface IAppUrlParams {
    contractId: string;
    messageId: string;
    replyTo: string;
    serviceEmail: string;
    serviceType: IServiceType;
    token?: string;
    analyticsOptOut: boolean;
    sessionKey?: string;
    streamCode?: string;
}

export interface IAuthDetails {
    accountCode: string;
    apiUrl: string;
    authToken: string;
    username: string;
    tokenExp: number;
    analyticsOptOut: boolean;
    userId: string;
    accountId: string;
    sessionKey?: string;
    streamCode?: string
}
