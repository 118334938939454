import {
    FLIP_GROUPS,
    IFlipGroupsAction,
    IPartiesType,
    IParty,
    IResetSelectionsAction,
    ISetContractScrollToPartyAction,
    ISetDraggedPartyAction,
    ISetExpandedGroupAction,
    ISetHighlightingAction,
    ISetPartiesAction,
    ISetScrollToPartyAction,
    RESET_SELECTIONS,
    SET_CONTRACT_SCROLL_TO_PARTY,
    SET_DRAGGED_PARTY,
    SET_EXPANDED_GROUP,
    SET_HIGHLIGHTING,
    SET_PARTIES,
    SET_SCROLL_TO_PARTY,
} from './Parties-types';

export function flipGroupsAction(typeA: IPartiesType, typeB: IPartiesType): IFlipGroupsAction {
    return {
        type: FLIP_GROUPS,
        typeA,
        typeB,
    };
}

export function resetSelectionsAction(): IResetSelectionsAction {
    return {
        type: RESET_SELECTIONS,
    }
}

export function setContractScrollToPartyAction(party: IParty): ISetContractScrollToPartyAction {
    return {
        type: SET_CONTRACT_SCROLL_TO_PARTY,
        party,
    };
}

export function setDraggedPartyAction(party: IParty): ISetDraggedPartyAction {
    return {
        type: SET_DRAGGED_PARTY,
        party,
    };
}

export function setExpandedGroupAction(expandedGroup: IPartiesType): ISetExpandedGroupAction {
    return {
        type: SET_EXPANDED_GROUP,
        expandedGroup,
    }
}

export function setPartiesAction(parties: IParty[]): ISetPartiesAction {
    return {
        type: SET_PARTIES,
        parties,
    };
}

export function setScrollToPartyAction(party: IParty): ISetScrollToPartyAction {
    return {
        type: SET_SCROLL_TO_PARTY,
        party,
    };
}

export function setHighlightingAction(highlighting: boolean): ISetHighlightingAction {
    return {
        type: SET_HIGHLIGHTING,
        highlighting,
    };
}
