import {
    IMixpanelHelper,
} from './MixpanelHelper-types';

const mixpanel = require('mixpanel-browser');
const moment = require('moment');

export default class MixpanelHelper implements IMixpanelHelper {
    private project: string;

    constructor(project: string) {
        this.project = project;
    }

    incrementProperty(property: string) {
        const project = this.project;
        const value = mixpanel[project].get_property(property);
        const update = {};

        if (value && typeof value === 'number') {
            update[property] = value + 1;
        } else {
            update[property] = 1;
        }

        mixpanel[project].register(update);

        if (mixpanel[project].people) {
            mixpanel[project].people.increment(property, 1);
        }
    };

    registerFirstProperty(property: string, customValue: string = '') {
        const project = this.project;
        const label = `First ${property}`;
        const value = mixpanel[project].get_property(label);
        const update = {};

        if (!value) {
            update[label] = customValue ? customValue : moment().format();

            if (typeof mixpanel.register_once === 'function') {
                mixpanel[project].register_once(update);
            }

            if (mixpanel[project].people) {
                mixpanel[project].people.set(label, update[label]);
            }
        }
    }

    registerLastProperty(property: string, customValue: string = '') {
        const project = this.project;
        const label = `Last ${property}`;
        mixpanel[project].get_property(label);
        const update = {
            [label]: customValue ? customValue : moment().format(),
        };
        mixpanel[project].register(update);

        if (mixpanel[project].people) {
            mixpanel[project].people.set(label, update[label]);
        }
    }
}
