export const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED';
export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';
export const ERROR_SERVER = 'ERROR_SERVER';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';

export interface IApiStatic {
    request: (method: IMethod, url: string, payload: any, authorize: boolean) => Promise<any>;
    apiUrl: string;
    authToken: string;
}

export interface IApi  {
    apiUrl: string;
    authToken: string;
}

export enum IMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}
