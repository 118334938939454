import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import Sidebar from './Sidebar';
import { ISidebarStateProps, ISidebarDispatchProps } from './Sidebar-types';
import { setAppStatusAction } from '../App/App-actions';
import { IAppStatus } from '../App/App-types';
import {
    hideDialogAction,
    showDialogAction,
} from '../ModalDialog/ModalDialog-actions';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';

function mapStateToProps(state: IRootState): ISidebarStateProps {
    return {
        expandedGroup: state.parties.expandedGroup,
        parties: state.parties.parties,
        contractId: state.app.contractId,
        versionId: state.app.versionId,
        versionNumber: state.app.versionNumber,
        apiBaseUrl: state.app.apiUrl,
        authToken: state.app.authToken,
        messageId: state.app.messageId,
        replyTo: state.app.replyTo,
        serviceEmail: state.app.serviceEmail,
        serviceType: state.app.serviceType,
        streamCode: state.app.stream,
        tokenExp: state.app.tokenExp,
        accountCode: state.app.accountCode,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): ISidebarDispatchProps {
    return {
        hideDialog: () => dispatch(hideDialogAction()),
        setAppStatus: (status: IAppStatus, statusMessage?: string) =>
            dispatch(setAppStatusAction(status, statusMessage)),
        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height
                )
            ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
