import Api from '../services/api';
import { IMethod } from '../services/api-types';
import {
    IAuthResourceStatic,
    IAuthResource,
    IAuthResponse,
    IAuthMetadataResponse,
    IUsersResponse,
} from './auth-types';

const AuthResource: IAuthResourceStatic = class AuthResource implements IAuthResource {

    static async login(accountCode: string, username: string, password: string): Promise<IAuthResponse> {
        const payload = {
            account_code: accountCode,
            username,
            password,
            grant_type: 'password'
        }
        return await (await Api.request(IMethod.POST, '/authenticate/', payload, false)).json();
    };

    static async getAuthMetadata(): Promise<IAuthMetadataResponse> {
        return await (await Api.request(IMethod.GET, '/details')).json();
    }

    static async getUsers(): Promise<IUsersResponse> {

        console.log(await Api.request(IMethod.GET, '/users'));

        return await (await Api.request(IMethod.GET, '/users')).json();
    }

}

export default AuthResource;
