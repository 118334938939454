import { IAppReducer } from '../components/App/App-types';
import { reduxStore } from '../redux/store';
import Api from '../services/api';
import { IMethod } from '../services/api-types';
import { fetchContractManagement } from './contract-management';
import {
  IParagraphResourceStatic,
  IParagraphResource,
} from './paragraph-types';

const ParagraphResource: IParagraphResourceStatic = class ParagraphResource
  implements IParagraphResource
{
  static async getParagraphs(
    contractId: string,
    versionId: string
  ): Promise<any> {
    const { sessionKey, stream } = reduxStore.store.getState()
      .app as IAppReducer;

    if (sessionKey) {
      return fetchContractManagement({
        path: `/v0_1/contracts/${contractId}/versions/${versionId}/paragraphs`,
        sessionKey,
        streamCode: stream,
      });
    } else {
      return await (
        await Api.request(
          IMethod.GET,
          `/contracts/${contractId}/versions/${versionId}/paragraphs`
        )
      ).json();
    }
  }
};

export default ParagraphResource;
