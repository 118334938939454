import {
    IAppActionTypes,
    IAppReducer,
    IAppStatus,
    IServiceType,
    ISetAccountCodeAction,
    ISetApiUrlAction,
    ISetAppStatusAction,
    ISetAuthDetailsAction,
    ISetAuthTokenAction,
    ISetContractIdAction,
    ISetMessageIdAction,
    ISetReplyToAction,
    ISetServiceEmailAction,
    ISetServiceTypeAction,
    ISetUsernameAction,
    ISetVersionIdAction,
    ISetVersionNumberAction,
    IUserDetails,
    SET_ACCOUNT_CODE,
    SET_API_URL,
    SET_APP_STATUS,
    SET_AUTH_DETAILS,
    SET_AUTH_TOKEN,
    SET_CONTRACT_ID,
    SET_MESSAGE_ID,
    SET_REPLY_TO,
    SET_SERVICE_EMAIL,
    SET_SERVICE_TYPE,
    SET_USERNAME,
    SET_USER_DETAILS,
    SET_VERSION_ID,
    SET_VERSION_NUMBER,
} from './App-types';

const initialState: IAppReducer = {
    accountCode: null,
    apiUrl: null,
    authToken: null,
    contractId: null,
    messageId: null,
    replyTo: null,
    serviceEmail: null,
    serviceType: null,
    status: IAppStatus.LOADING,
    statusMessage: '',
    username: null,
    versionId: null,
    versionNumber: null,
    tokenExp: null,
    analyticsOptOut: true,
    stream: null,
    user: {
        firstName: '',
        id: '',
        lastName: '',
        email: '',
        roles: [],
        tier: '',
    },
};

const AppReducer =
    (state = initialState, action: IAppActionTypes): IAppReducer => {
        switch (action.type) {
            case SET_ACCOUNT_CODE:
                return {
                    ...state,
                    accountCode: (action as ISetAccountCodeAction).accountCode,
                };
            case SET_API_URL:
                return {
                    ...state,
                    apiUrl: (action as ISetApiUrlAction).apiUrl,
                };
            case SET_AUTH_TOKEN:
                return {
                    ...state,
                    authToken: (action as ISetAuthTokenAction).authToken,
                };
            case SET_CONTRACT_ID:
                return {
                    ...state,
                    contractId: (action as ISetContractIdAction).contractId,
                };
            case SET_VERSION_ID:
                return {
                    ...state,
                    versionId: (action as ISetVersionIdAction).versionId,
                };
            case SET_VERSION_NUMBER:
                return {
                    ...state,
                    versionNumber: (action as ISetVersionNumberAction).versionNumber,
                };
            case SET_USERNAME:
                return {
                    ...state,
                    username: (action as ISetUsernameAction).username,
                };
            case SET_MESSAGE_ID:
                return {
                    ...state,
                    messageId: (action as ISetMessageIdAction).messageId,
                };
            case SET_REPLY_TO:
                return {
                    ...state,
                    replyTo: (action as ISetReplyToAction).replyTo,
                };
            case SET_SERVICE_EMAIL:
                return {
                    ...state,
                    serviceEmail: (action as ISetServiceEmailAction).serviceEmail,
                };
            case SET_SERVICE_TYPE:
                const serviceType = (action as ISetServiceTypeAction).serviceType as IServiceType;
                return {
                    ...state,
                    serviceType: String(serviceType).toLowerCase() as any,
                };
            case SET_USER_DETAILS:
                const userDetails: IUserDetails = {
                    firstName: action.firstName,
                    id: action.id,
                    lastName: action.lastName,
                    email: action.email,
                    roles: action.roles,
                    tier: action.tier,
                };

                return {
                    ...state,
                    user: userDetails,
                };
            case SET_AUTH_DETAILS:
                const {
                    accountCode,
                    analyticsOptOut,
                    apiUrl,
                    authToken,
                    tokenExp,
                    username,
                    sessionKey,
                    streamCode,
                } = action as ISetAuthDetailsAction;

                return {
                    ...state,
                    authToken,
                    apiUrl,
                    accountCode,
                    username,
                    tokenExp,
                    analyticsOptOut,
                    sessionKey,
                    stream: streamCode ?? state.stream,
                };
            case SET_APP_STATUS:
                const { status, statusMessage } = action as ISetAppStatusAction;

                return {
                    ...state,
                    status,
                    statusMessage,
                };
            default:
                return state;
        }
    };

export default AppReducer;
