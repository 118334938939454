import {
    IFeatureToggleProviderActionTypes,
    IFeatureToggleProviderReducer,
    SET_FEATURE_TOGGLES,
} from './FeatureToggleProvider-types';

const initialState: IFeatureToggleProviderReducer = {
    toggles: [],
};

const FeatureToggleProviderReducer =
    (state = initialState, action: IFeatureToggleProviderActionTypes): IFeatureToggleProviderReducer => {
        switch (action.type) {
            case SET_FEATURE_TOGGLES:
                return {
                    ...state,
                    toggles: [...action.toggles],
                };
            default:
                return state;
        }
    };

export default FeatureToggleProviderReducer;
