import React from 'react';
import {
    Domain,
    DomainDisabled,
    KeyboardBackspace,
    Delete,
    RestoreFromTrash,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import { IParty, IPartyVariantType, IPartiesType } from '../Parties/Parties-types';
import { IPartyProps } from './Party-types';
import { isInternetExplorer } from '../Parties/Parties-helpers';

const throttle = require('lodash.throttle');

class Party extends React.Component<IPartyProps> {

    onDiscardParty(party: IParty) {
        const { discardTo, removeParty, setSelectedParty } = this.props;

        if (party.originalType) {
            this.onMoveParty(party, discardTo);
        } else {
            setSelectedParty(null);
            removeParty(party);
        }
    }

    onDragStart(event: React.DragEvent<HTMLDivElement>, party: IParty) {
        const format = isInternetExplorer() ? 'Text' : 'text/plain';
        event.dataTransfer.setData(format, String(party.id));
        this.props.setDraggedParty(party);
    }

    onDragEnd() {
        this.props.setDraggedParty(null);
    }

    onHoverOffParty() {
        this.props.setHighlightedParty(null);
    }

    onHoverParty = throttle(function onHoverParty(
        party: IParty, highlightedParty: IParty, selectedParty: IParty, setHighlightedParty: any
    ) {
        const isHighlightedParty = highlightedParty && highlightedParty.id === party.id;
        const isSelectedParty = selectedParty && selectedParty.id === party.id

        if ((!highlightedParty && (!selectedParty || !isSelectedParty))
            || (highlightedParty && !isHighlightedParty)) {
            setHighlightedParty(party);
        }
    }, 50);

    onMoveParty(party: IParty, type: IPartiesType) {
        if (Object.values(IPartiesType).includes(type) &&
            party.type !== type) {
            const updatedParty = { ...party, type };
            this.props.updateParty(updatedParty);
            this.props.setScrollToParty(updatedParty);
        }
    }

    onRestoreParty(party: IParty) {
        if (party.type !== party.originalType) {
            this.onMoveParty(party, party.originalType);
        }
    }

    onSelectParty(event: any, party: IParty) {
        const {
            selectedParty,
            setSelectedParty,
            setContractScrollToParty
        } = this.props;
        const { className } = event.target;

        if (typeof className === 'object') {
            return;
        }

        if (['party-info', 'party-name'].includes(className) &&
            selectedParty &&
            selectedParty.id === party.id) {
            setSelectedParty(null);
        } else {
            setSelectedParty(party);
            setContractScrollToParty(party);
        }
    }

    onToggleFormalParty(party: IParty) {
        const { FORMAL, REFERENCE } = IPartyVariantType;
        const variantType = party.variantType === FORMAL ? REFERENCE : FORMAL;
        this.props.updateParty({ ...party, variantType });
    }

    renderActionControls(party: IParty) {
        const { restoreByDefault } = this.props;

        const isFormalParty = party.variantType === IPartyVariantType.FORMAL;

        return <div className="action-controls">
            {isFormalParty ?
                <Tooltip
                    title="Remove as formal party name"
                    placement="bottom"
                    classes={{ tooltip: 'mui-tooltip' }}
                    enterDelay={300}
                >
                    <DomainDisabled className="control-icon alt-hover" onClick={() => this.onToggleFormalParty(party) }/>
                </Tooltip> :
                <Tooltip
                    title="Set as formal party name"
                    placement="bottom"
                    classes={{ tooltip: 'mui-tooltip' }}
                    enterDelay={300}
                >
                    <Domain className="control-icon" onClick={() => this.onToggleFormalParty(party) } />
                </Tooltip>
            }
            {restoreByDefault && party.originalType ?
                (party.originalType !== party.type ?
                    <Tooltip
                        title="Restore party"
                        placement="bottom"
                        classes={{ tooltip: 'mui-tooltip' }}
                        enterDelay={300}
                    >
                        <RestoreFromTrash className="control-icon restore" onClick={() => this.onRestoreParty(party)} />
                    </Tooltip>
                    : null
                ) :
                <Tooltip
                    title="Discard party"
                    placement="bottom"
                    classes={{ tooltip: 'mui-tooltip' }}
                    enterDelay={300}
                >
                    <Delete className="control-icon" onClick={() => this.onDiscardParty(party)}/>
                </Tooltip>
            }
        </div>
    }

    renderFlipPartyControls(party: IParty) {
        const { prevType, nextType } = this.props;

        return (<span className="flip-controls">
            <span className="flip-control flip-prev" onClick={() => this.onMoveParty(party, prevType)}>
                <Tooltip
                    title="Flip party"
                    placement="bottom"
                    classes={{ tooltip: 'mui-tooltip' }}
                    enterDelay={300}
                >
                    <KeyboardBackspace className={`flip-icon ${ !prevType ? 'disabled' : ''}`} />
                </Tooltip>
            </span>
            <span className="flip-control flip-next" onClick={() => this.onMoveParty(party, nextType)}>
                <Tooltip
                    title="Flip party"
                    placement="bottom"
                    classes={{ tooltip: 'mui-tooltip' }}
                    enterDelay={300}
                >
                    <KeyboardBackspace className={`flip-icon ${ !nextType ? 'disabled' : ''}`} />
                </Tooltip>
            </span>
        </span>);
    }

    truncatePartyName(text: string, length: number) {
        return text.length > length ? text.slice(0, length).trim() + '...' : text;
    }

    render() {
        const { highlightedParty, party, selectedParty, setHighlightedParty } = this.props;
        const isSelected = selectedParty && selectedParty.id === party.id;
        const isHighlighted = highlightedParty && highlightedParty.id === party.id;
        const classList = `party ${isHighlighted ? 'highlighted' : ''} ${isSelected ? 'selected' : ''}`;
        const isFormalParty = party.variantType === IPartyVariantType.FORMAL;
        const partyNameMaxChars = isSelected ? 22 : 32;

        return <li className={classList} onClick={(event: any) => { this.onSelectParty(event, party) }} id={`party-${party.id}`}>
            <div className="party-info"
                draggable
                onDragStart={(event) => this.onDragStart(event, party)}
                onDragEnd={() => this.onDragEnd()}
                onMouseEnter={() => this.onHoverParty(party, highlightedParty, selectedParty, setHighlightedParty)}
                onMouseLeave={() => this.onHoverOffParty()}
            >
                <span className="party-name">{this.truncatePartyName(party.name, partyNameMaxChars)}</span>
                {isFormalParty ?
                    <span className="variant-type">
                        <Tooltip
                            title="Formal party"
                            placement="bottom"
                            classes={{ tooltip: 'mui-tooltip' }}
                            enterDelay={300}
                        >
                            <Domain className="variant-type-icon" />
                        </Tooltip>
                    </span>
                    : null
                }
                {this.renderFlipPartyControls(party)}
            </div>
            {isSelected ? this.renderActionControls(party) : null}
        </li>;
    }
}

export default Party;
