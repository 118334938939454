import { IAuthToken } from 'types/thoughtriver';
import { IAuthDetails, AUTH_GATEWAY_PASSWORD } from './App-types';

const jwtDecode = require('jwt-decode');
const moment = require('moment');

export function decodeToken(
    authToken: string,
    analyticsOptOut: boolean = false
): IAuthDetails {
    const decodedToken = jwtDecode(authToken) as IAuthToken;

    const gateway = decodedToken.gty;

    const accountCode = decodedToken.accountCode.split('/')[0];

    return {
        authToken,
        apiUrl: (decodedToken.aud
            ? decodedToken.aud
            : decodedToken.iss
        ).replace(/\/$/, ''),
        username: gateway === AUTH_GATEWAY_PASSWORD ? decodedToken.sub : '',
        accountCode,
        tokenExp: decodedToken.exp,
        analyticsOptOut: analyticsOptOut,
        userId: decodedToken.userId,
        accountId: decodedToken.accountId,
    };
}

export function tokenIsValid(expTimestamp: number, bufferInMins: number = 30) {
    const expThreshold = moment().subtract(bufferInMins, 'minutes').format();
    return moment.unix(expTimestamp).isAfter(expThreshold);
}

export function capitalize(text: string = ''): string {
    return typeof text === 'string'
        ? `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`
        : '';
}
