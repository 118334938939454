import { IPartiesType, IParty } from '../Parties/Parties-types';
import { Action } from 'redux';

export const ADD_PARTY = 'ADD_PARTY';
export const REMOVE_PARTY = 'REMOVE_PARTY';
export const SET_SELECTED_PARTY = 'SET_SELECTED_PARTY';
export const UPDATE_PARTY = 'UPDATE_PARTY';

export interface IPartyDispatchProps {
    setHighlightedParty: (party: IParty) => void;
    setSelectedParty: (party: IParty) => void;
    setDraggedParty: (draggedParty: IParty) => void;
    updateParty: (party: IParty) => void;
    removeParty: (party: IParty) => void;
    setScrollToParty: (party: IParty) => void;
    setContractScrollToParty: (party: IParty) => void;
}

export interface IPartyProps extends IPartyDispatchProps, IPartyStateProps {
    party: IParty;
    prevType?: IPartiesType;
    nextType?: IPartiesType;
    restoreByDefault?: boolean;
    discardTo: IPartiesType;
}

export interface IPartyStateProps {
    highlightedParty: IParty;
    selectedParty: IParty;
}

export interface ISetSelectedPartyAction extends Action {
    type: typeof SET_SELECTED_PARTY;
    selectedParty: IParty;
}

export interface IUpdatePartyAction extends Action {
    type: typeof UPDATE_PARTY;
    party: IParty;
}

export interface IAddPartyAction extends Action {
    type: typeof ADD_PARTY;
    partyToAdd: string;
    partyType: IPartiesType;
}

export interface IRemovePartyAction extends Action {
    type: typeof REMOVE_PARTY;
    party: IParty;
}
