import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import App from './App';
import { IParagraph } from 'types/thoughtriver';
import {
    IAppDispatchProps,
    IAppStateProps,
    IAppStatus,
    IAuthDetails,
    IServiceType,
    IUserDetails,
} from './App-types';
import {
    setContractNameAction,
    setParagraphsAction,
} from '../Contract/Contract-actions';
import { IParty } from '../Parties/Parties-types';
import {
    resetSelectionsAction,
    setDraggedPartyAction,
    setHighlightingAction,
    setPartiesAction,
} from '../Parties/Parties-actions';
import {
    setAppStatusAction,
    setAuthDetailsAction,
    setContractIdAction,
    setMessageIdAction,
    setReplyToAction,
    setServiceEmailAction,
    setServiceTypeAction,
    setUserDetailsAction,
    setVersionIdAction,
    setVersionNumberAction,
} from './App-actions';
import { IRootState } from '../../redux/reducers-types';
import { setAnalyticsInitialised } from '../../services/Analytics-actions';

function mapStateToProps(state: IRootState): IAppStateProps {
    return {
        accountCode: state.app.accountCode,
        apiUrl: state.app.apiUrl,
        authToken: state.app.authToken,
        contractId: state.app.contractId,
        contractName: state.contract.name,
        messageId: state.app.messageId,
        replyTo: state.app.replyTo,
        serviceEmail: state.app.serviceEmail,
        serviceType: state.app.serviceType,
        status: state.app.status,
        statusMessage: state.app.statusMessage,
        username: state.app.username,
        versionId: state.app.versionId,
        tokenExp: state.app.tokenExp,
        analyticsOptOut: state.app.analyticsOptOut,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IAppDispatchProps {
    return {
        resetSelections: () => dispatch(resetSelectionsAction()),
        setAppStatus: (status: IAppStatus, statusMessage?: string) =>
            dispatch(setAppStatusAction(status, statusMessage)),
        setAuthDetails: (authDetails: IAuthDetails) => dispatch(setAuthDetailsAction(authDetails)),
        setAnalyticsInitialised: () => dispatch(setAnalyticsInitialised()),
        setContractId: (contractId: string) => dispatch(setContractIdAction(contractId)),
        setContractName: (name: string) => dispatch(setContractNameAction(name)),
        setDraggedParty: (party: IParty) => dispatch(setDraggedPartyAction(party)),
        setHighlighting: (highlighting: boolean) => dispatch(setHighlightingAction(highlighting)),
        setParagraphs: (paragraphs: IParagraph[]) => dispatch(setParagraphsAction(paragraphs)),
        setParties: (parties: IParty[]) => dispatch(setPartiesAction(parties)),
        setVersionId: (versionId: string) => dispatch(setVersionIdAction(versionId)),
        setVersionNumber: (versionNumber: number) => dispatch(setVersionNumberAction(versionNumber)),
        setMessageId: (messageId: string) => dispatch(setMessageIdAction(messageId)),
        setReplyTo: (replyTo: string) => dispatch(setReplyToAction(replyTo)),
        setServiceEmail: (serviceEmail: string) => dispatch(setServiceEmailAction(serviceEmail)),
        setServiceType: (serviceType: IServiceType) => dispatch(setServiceTypeAction(serviceType)),
        setUserDetails: (userDetails: IUserDetails) => dispatch(setUserDetailsAction(userDetails)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
