import { IAppReducer } from '../components/App/App-types';
import { reduxStore } from '../redux/store';
import Api from '../services/api';
import { IMethod } from '../services/api-types';
import { fetchContractManagement } from './contract-management';
import {
  IContractResourceStatic,
  IContractResource,
  IContractResponse,
  IContractsResponse,
} from './contract-types';

const ContractResource: IContractResourceStatic = class ContractResource
  implements IContractResource
{
  static async getContract(contractId: string): Promise<IContractResponse> {
    const { sessionKey, stream } = reduxStore.store.getState()
      .app as IAppReducer;

    if (sessionKey) {
      return fetchContractManagement({
        path: `/v0_1/contracts/${contractId}`,
        sessionKey,
        streamCode: stream,
      });
    } else {
      return await (
        await Api.request(IMethod.GET, `/contracts/${contractId}`)
      ).json();
    }
  }

  static async getContracts(
    page: number = 1,
    contractId: string = null
  ): Promise<IContractsResponse> {
    const queryParams = ['filter[all-streams]=1'];

    if (page !== null) {
      queryParams.push(`page[number]=${page}`);
    }

    if (contractId) {
      queryParams.push(`filter[contract-ids]=${contractId}`);
    }

    return await (
      await Api.request(IMethod.GET, `/contracts?${queryParams.join('&')}`)
    ).json();
  }
};

export default ContractResource;
