import {
    IParty,
    IPartiesType,
    IPartyVariantType,
    VARIANTS,
    FORMAL_NAMES,
    REFERENCE_NAMES
} from './Parties-types';
import { IPartyGroups } from 'types/thoughtriver';

export function alphabetizeParties(partyA: IParty, partyB: IParty) {
    return partyA.name && partyA.name.localeCompare(partyB.name, undefined, { numeric: true, sensitivity: 'base' });
}

export function mapPartiesToOverrides(partyGroups: IPartyGroups): IParty[] {
    const parties: IParty[] = [];
    let index = 0;
    let variant;
    let variantGroup;

    for (const type in partyGroups) {
        if (type === IPartiesType.DISCARDED) {
            for (const party in partyGroups[type]) {
                parties.push({
                    id: index,
                    name: partyGroups[type][party],
                    type,
                    originalType: type,
                    variantType: IPartyVariantType.REFERENCE,
                });

                index++;
            }
        }

        if (type === IPartiesType.RECIPROCAL) {
            for (variantGroup in partyGroups[type]) {
                for (variant in  partyGroups[type][variantGroup][VARIANTS]) {
                    parties.push({
                        id: index,
                        name: partyGroups[type][variantGroup][VARIANTS][variant]['name'],
                        type,
                        originalType: type,
                        variantType: IPartyVariantType.REFERENCE,
                    });

                    index++;
                }
            }
        }

        if (type === IPartiesType.OWN_PARTIES ||
            type === IPartiesType.COUNTER_PARTIES) {
                for (const group of partyGroups[type]) {
                    for (variantGroup in group[FORMAL_NAMES]) {
                        for (variant in group[FORMAL_NAMES][variantGroup][VARIANTS]) {
                            parties.push({
                                id: index,
                                name: group[FORMAL_NAMES][variantGroup][VARIANTS][variant]['name'],
                                type,
                                originalType: type,
                                variantType: IPartyVariantType.FORMAL,
                            });

                            index++;
                        }
                    }

                    for (variantGroup in group[REFERENCE_NAMES]) {
                        for (variant in group[REFERENCE_NAMES][variantGroup][VARIANTS]) {
                            parties.push({
                                id: index,
                                name: group[REFERENCE_NAMES][variantGroup][VARIANTS][variant]['name'],
                                type,
                                originalType: type,
                                variantType: IPartyVariantType.REFERENCE,
                            });

                            index++;
                        }
                    }
                }
        }
    }

    return parties.sort(alphabetizeParties);

}

export function mapOverridesToParties(parties: IParty[]): IPartyGroups {
    let partyGroups: IPartyGroups = {
        'excluded-terms': [],
        'reciprocal-reference-names': [],
        'own-parties': [{
            'formal-names': [],
            'reference-names': [],
        }],
        'counter-parties': [{
            'formal-names': [],
            'reference-names': [],
        }],
    };

    for (const party of parties) {
        switch(party.type) {
            case IPartiesType.DISCARDED:
                partyGroups['excluded-terms'].push(party.name);
                break;
            case IPartiesType.RECIPROCAL:
                partyGroups['reciprocal-reference-names'].push({
                    variants: [{
                        name: party.name,
                    }],
                });
                break;
            case IPartiesType.OWN_PARTIES:
            case IPartiesType.COUNTER_PARTIES:
                if (party.variantType === IPartyVariantType.FORMAL) {
                    partyGroups[party.type][0]['formal-names'].push({
                        variants: [{
                            name: party.name,
                        }],
                    });
                } else {
                    partyGroups[party.type][0]['reference-names'].push({
                        variants: [{
                            name: party.name,
                        }],
                    });
                }
                break;
            default:
                throw new Error('Invalid party type');
        }
    }

    return partyGroups;
}

export function sortParties(parties: IParty[]): IParty[] {
    const partiesCopy = [...parties];

    partiesCopy.sort((partyA, partyB) => {
        return partyB.name.length - partyA.name.length;
    });

    return partiesCopy;
}

export function reIndex(party: IParty, index: number) {
    party.id = index;
    return party;
}

export function getMatchPattern(terms: string[]): string {
    return terms.map(term => escapeRegex(term)).join('|');
}

export function escapeRegex(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function isInternetExplorer() {
    return navigator && navigator.userAgent &&
    navigator.userAgent.toLowerCase().indexOf('trident') !== -1;
}
