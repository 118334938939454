import { Action } from 'redux';
import { ReactElement } from 'react';
import {
    ISetSelectedPartyAction,
    IUpdatePartyAction,
    IPartyStateProps,
    IAddPartyAction,
    IRemovePartyAction
 } from '../Party/Party-types';
import { ISetHighlightedPartyAction, ISetPartyToAddAction } from '../Paragraph/Paragraph-types';

export const FLIP_GROUPS = 'FLIP_GROUPS';
export const FORMAL_NAMES = 'formal-names';
export const REFERENCE_NAMES = 'reference-names';
export const RESET_SELECTIONS = 'RESET_SELECTIONS';
export const SET_CONTRACT_SCROLL_TO_PARTY = 'SET_CONTRACT_SCROLL_TO_PARTY';
export const SET_DRAGGED_PARTY = 'SET_DRAGGED_PARTY';
export const SET_EXPANDED_GROUP = 'SET_EXPANDED_GROUP';
export const SET_PARTIES = 'SET_PARTIES';
export const SET_SCROLL_TO_PARTY = 'SET_SCROLL_TO_PARTY';
export const VARIANTS = 'variants';
export const SET_HIGHLIGHTING = 'SET_HIGHLIGHTING';

export interface IPartiesDispatchProps {
    addParty: (partyToAdd: string, partyType: IPartiesType) => void;
    flipGroups: (typeA: IPartiesType, typeB: IPartiesType) => void;
    setDraggedParty: (draggedParty: IParty) => void;
    setExpandedGroup: (group: IPartiesType) => void;
    setHighlighting: (highlighting: boolean) => void;
    setScrollToParty: (party: IParty) => void;
    setSelectedParty: (party: IParty) => void;
    updateParty: (party: IParty) => void;
}

export interface IPartiesProps extends IPartiesDispatchProps, IPartiesStateProps {
    collapsible?: boolean;
    discardTo: IPartiesType;
    flipWith?: IPartiesType;
    nextType?: IPartiesType;
    prevType?: IPartiesType;
    restoreByDefault?: boolean;
    subtitle: ReactElement;
    title: string;
    type: IPartiesType;
    overlay?: boolean;
    compact?: boolean;
}

export interface IPartiesStateProps {
    expandedGroup: IPartiesType;
    parties: IParty[];
    partyToAdd: string;
    selectedParty: IParty;
    scrollToParty: IParty;
    draggedParty: IParty;
}

export interface IPartiesReducer extends IPartiesStateProps, IPartyStateProps {
    contractScrollToParty: IParty;
    highlightedParty: IParty;
    highlighting: boolean;
}

export enum IPartiesType {
    OWN_PARTIES = 'own-parties',
    COUNTER_PARTIES = 'counter-parties',
    RECIPROCAL = 'reciprocal-reference-names',
    DISCARDED = 'excluded-terms',
}

export enum IPartyVariantType {
    FORMAL = 'formal',
    REFERENCE = 'reference',
}

export interface IParty {
    id: number;
    name: string;
    type: IPartiesType;
    originalType: IPartiesType;
    variantType: IPartyVariantType;
}

export interface ISetContractScrollToPartyAction extends Action {
    type: typeof SET_CONTRACT_SCROLL_TO_PARTY;
    party: IParty;
}

export interface IFlipGroupsAction extends Action {
    type: typeof FLIP_GROUPS;
    typeA: IPartiesType;
    typeB: IPartiesType;
}

export interface IResetSelectionsAction extends Action {
    type: typeof RESET_SELECTIONS;
}

export interface ISetDraggedPartyAction extends Action {
    type: typeof SET_DRAGGED_PARTY;
    party: IParty;
}

export interface ISetExpandedGroupAction extends Action {
    type: typeof SET_EXPANDED_GROUP;
    expandedGroup: IPartiesType;
}

export interface ISetPartiesAction extends Action {
    type: typeof SET_PARTIES;
    parties: IParty[];
}

export interface ISetScrollToPartyAction extends Action {
    type: typeof SET_SCROLL_TO_PARTY;
    party: IParty;
}

export interface ISetHighlightingAction extends Action {
    type: typeof SET_HIGHLIGHTING;
    highlighting: boolean;
}

export type IPartiesActionTypes =
    IAddPartyAction |
    IFlipGroupsAction |
    IRemovePartyAction |
    IResetSelectionsAction |
    ISetContractScrollToPartyAction |
    ISetDraggedPartyAction |
    ISetExpandedGroupAction |
    ISetHighlightedPartyAction |
    ISetHighlightingAction |
    ISetPartiesAction |
    ISetPartyToAddAction |
    ISetScrollToPartyAction |
    ISetSelectedPartyAction |
    IUpdatePartyAction;

