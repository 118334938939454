import {
    SET_INITIALISED,
    ISetInitialisedAction,
} from './Analytics-types';

export function setAnalyticsInitialised(): ISetInitialisedAction {
    return {
        type: SET_INITIALISED,
    };
}
