import { Action } from 'redux';

export const SET_INITIALISED = 'SET_INITIALISED';
export const CATEGORY_USER = 'User';
export const ACTION_SET_PARTIES_CONFIRMATION_CONTINUE = 'Set Parties Confirmation - Continue';
export const ACTION_SPECIFY_PARTIES = 'Specify Parties';

export const MP_SHARED = 'SHARED';

export interface ISetInitialisedAction extends Action {
    type: typeof SET_INITIALISED;
}

export type AnalyticsActionTypes = ISetInitialisedAction;

export interface IAnalyticsState {
    initialised: boolean;
}

export interface IAnalyticsEnv {
    mpSharedTrackingId: string;
}
