import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  IAppProps,
  IAppStatus,
  IAppUrlParams,
  IAuthDetails,
  IServiceType,
} from './App-types';
import logo from '../../assets/logo.svg';
import logoDark from '../../assets/logo-dark.svg';
import Contract from '../Contract/Contract-container';
import ContractResource from '../../resources/contract';
import { decodeToken, tokenIsValid } from './App-helpers';
import {
  getRelationship,
  getLatestRelationshipId,
  getAttribute,
} from '../../resources/resource-helpers';
import Login from '../Login/Login-container';
import { mapPartiesToOverrides } from '../Parties/Parties-helpers';
import ParagraphResource from '../../resources/paragraph';
import Sidebar from '../Sidebar/Sidebar-container';
import VersionResource from '../../resources/version';
import { ERROR_UNAUTHORIZED } from '../../services/api-types';
import ModalDialog from '../ModalDialog/ModalDialog-container';
import { analyticsService } from '../../services/Analytics';
import AuthResource from '../../resources/auth';
import { IAuthMetadataResponse } from '../../resources/auth-types';

class App extends React.Component<IAppProps> {
  async componentDidMount() {
    const urlParams = this.getUrlParams();
    const {
      token,
      contractId,
      messageId,
      replyTo,
      serviceEmail,
      serviceType,
      analyticsOptOut,
      sessionKey,
      streamCode,
    } = urlParams;

    let statusMessage;
    let decodedToken: IAuthDetails;
    let userDetails: IAuthMetadataResponse;

    this.props.setAppStatus(IAppStatus.LOADING);
    this.props.setHighlighting(false);
    this.props.setServiceType(serviceType);
    this.props.resetSelections();

    if (
      serviceType === IServiceType.WORD &&
      Office &&
      typeof Office.onReady === 'function'
    ) {
      await Office.onReady();
    }

    try {
      this.validateUrlParams(urlParams);
    } catch (error) {
      this.props.setAppStatus(
        IAppStatus.ERROR,
        (error as { message?: string }).message
      );
      return;
    }

    if (!sessionKey) {
      try {
        decodedToken = decodeToken(token, analyticsOptOut);
      } catch (error) {
        statusMessage = 'The provided API access token is invalid.';
        this.props.setAppStatus(IAppStatus.ERROR, statusMessage);
        return;
      }
    } else {
      decodedToken = {
        tokenExp: new Date().getTime() / 1000 + 60 * 60 * 24,
        authToken: '',
        accountCode: '',
        apiUrl: '',
        username: '',
        analyticsOptOut: false,
        userId: '',
        accountId: '',
        sessionKey,
        streamCode
      };
    }

    await this.props.setAuthDetails(decodedToken);
    await this.props.setContractId(contractId);
    await this.props.setMessageId(messageId);
    await this.props.setReplyTo(replyTo);
    await this.props.setServiceEmail(serviceEmail);

    if (!tokenIsValid(decodedToken.tokenExp)) {
      statusMessage = 'The provided API access token has expired.';
      this.props.setAppStatus(IAppStatus.LOGIN_REQUIRED, statusMessage);
      return;
    }

    try {
      this.validateUrlParams(urlParams);
    } catch (error) {
      this.props.setAppStatus(
        IAppStatus.ERROR,
        (error as { message?: string }).message
      );
      return;
    }

    if (!sessionKey) {
      try {
        userDetails = await AuthResource.getAuthMetadata();

        await this.props.setUserDetails({
          firstName: userDetails.user?.first_name ?? '',
          id: userDetails.user_uuid,
          lastName: userDetails.user?.last_name ?? '',
          email: userDetails.user?.email ?? '',
          roles: userDetails.roles,
          tier: userDetails.tier,
        });
      } catch (error) {
        console.log(error);
        this.props.setAppStatus(
          IAppStatus.ERROR,
          'Error fetching user details'
        );
      }

      try {
        await analyticsService.initialise(decodedToken);
        this.props.setAnalyticsInitialised();
      } catch {
        //
      }
    }

    await this.loadContract(contractId);
  }

  formatContractName(text: string) {
    return text.includes('.') ? text.split('.').slice(0, -1).join('') : text;
  }

  getUrlParams(): IAppUrlParams {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      token: urlParams.get('token'),
      contractId: urlParams.get('contractId'),
      messageId: urlParams.get('messageId'),
      replyTo: urlParams.get('replyTo'),
      serviceEmail: urlParams.get('serviceEmail'),
      serviceType:
        (urlParams.get('serviceType')?.toLowerCase() as IServiceType) ??
        IServiceType.FLOW,
      analyticsOptOut:
        urlParams.get('analyticsOptOut')?.toLowerCase() === 'true',
      sessionKey: urlParams.get('sessionKey'),
      streamCode: urlParams.get('streamCode'),
    };
  }

  async loadContract(contractId: string) {
    try {
      const contract = await ContractResource.getContract(contractId);
      const versions = getRelationship(contract, 'versions');
      const versionId = getLatestRelationshipId(versions);
      const version = await VersionResource.getVersion(contractId, versionId);
      const versionNumber = getAttribute(version, 'number');
      const paragraphs = await ParagraphResource.getParagraphs(
        contractId,
        versionId
      );

      if (
        this.props.contractId !== contractId ||
        this.props.versionId !== versionId
      ) {
        const parties = mapPartiesToOverrides(getAttribute(version, 'parties'));
        this.props.setParties(parties);
      }

      this.props.setContractId(contractId);
      this.props.setVersionId(versionId);
      this.props.setVersionNumber(versionNumber);
      this.props.setContractName(contract.data.attributes.name);
      this.props.setParagraphs(paragraphs.data);
      this.props.setAppStatus(IAppStatus.LOADED);
    } catch (error) {
      if ((error as { message?: string }).message === ERROR_UNAUTHORIZED) {
        this.props.setAppStatus(IAppStatus.LOGIN_REQUIRED);
      } else {
        this.props.setAppStatus(
          IAppStatus.ERROR,
          'Failed to load the contract.'
        );
      }
      return;
    }
  }

  onDragEnd(event: any) {
    event.preventDefault();
    this.props.setDraggedParty(null);
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  renderConfirmationMessage(serviceType: IServiceType) {
    let message = 'Thank you for updating the parties!';

    switch (String(serviceType).toLowerCase()) {
      case IServiceType.WORD:
        message +=
          ' You may close this window and the parties will be updated in Word shortly.';
        break;
      default:
        message += ' An email will be sent to you soon with a new assessment.';
    }

    return message;
  }

  validateUrlParams(urlParams: IAppUrlParams) {
    const {
      token,
      contractId,
      messageId,
      replyTo,
      serviceEmail,
      serviceType,
      sessionKey,
    } = urlParams;

    let errorMessage =
      'This page requires the following parameters to be provided in the URL: ' +
      'API access token (or session key), contract ID, message ID, reply-to email, and service email.';

    switch (String(serviceType).toLowerCase()) {
      case IServiceType.WORD:
        if (!contractId || (!token && !sessionKey)) {
          errorMessage =
            'This page requires an API access token (or session key) and contract ID ' +
            'to be provided in the URL.';
          throw new Error(errorMessage);
        }
        break;
      default:
        if (
          !contractId ||
          !messageId ||
          !replyTo ||
          !serviceEmail ||
          (!token && !sessionKey)
        ) {
          throw new Error(errorMessage);
        }
    }
  }

  render() {
    const { status, statusMessage, contractName, serviceType } = this.props;

    return (
      <div
        className="app-container"
        onDrop={(event) => this.onDragEnd(event)}
        onDragOver={this.onDragOver}
      >
        {status === IAppStatus.LOADED ? (
          <div className="app">
            <header className="header">
              <div className="page-title">
                {this.formatContractName(contractName)}
              </div>
              <div className="logo">
                <img
                  className="logo-img"
                  src={logo}
                  alt="ThoughtRiver Logo"
                />
              </div>
            </header>
            <div className="header sub-header">
              <div className="sub-header-text">
                We may not have identified all parties.
              </div>
              <div className="sub-header-text right">Party names</div>
            </div>
            <main className="main">
              <Contract />
              <Sidebar />
            </main>
          </div>
        ) : null}
        {status === IAppStatus.LOADING ? (
          <div className="app-loading-screen">
            <div className="splash-content">
              <div className="logo">
                <img
                  className="logo-img"
                  src={logoDark}
                  alt="ThoughtRiver Logo"
                />
              </div>
              <div className="loader-wrapper">
                <span className="loader"></span>{' '}
                <span>Loading contract...</span>
              </div>
            </div>
          </div>
        ) : null}
        {status === IAppStatus.ERROR ? (
          <div className="app-error-screen">
            <div className="splash-content">
              <div className="logo">
                <img
                  className="logo-img"
                  src={logoDark}
                  alt="ThoughtRiver Logo"
                />
              </div>
              <div className="message">{statusMessage}</div>
            </div>
          </div>
        ) : null}
        {status === IAppStatus.LOGIN_REQUIRED ? (
          <div className="app-login-screen">
            <div className="splash-content">
              <Login onSuccessCallback={this.loadContract.bind(this)} />
            </div>
          </div>
        ) : null}
        {status === IAppStatus.COMPLETED ? (
          <div className="app-completed-screen">
            <div className="splash-content">
              <div className="logo">
                <img
                  className="logo-img"
                  src={logoDark}
                  alt="ThoughtRiver Logo"
                />
              </div>
              <div className="message">
                {this.renderConfirmationMessage(serviceType)}
              </div>
            </div>
          </div>
        ) : null}
        <ToastContainer />
        <ModalDialog />
      </div>
    );
  }
}

export default App;
