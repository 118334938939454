import Api from '../services/api';
import { IMethod } from '../services/api-types';
import { IAssessmentResourceStatic, IAssessmentResource } from './assessment-types';

const AssessmentResource: IAssessmentResourceStatic = class AssessmentResource implements IAssessmentResource {

    static async getAssessment(contractId: string, versionId: string, assessmentId: string): Promise<any> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}`;
        return await (await Api.request(IMethod.GET, url)).json();
    };

    static async getAssessments(contractId: string, versionId: string): Promise<any> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments`;
        return await (await Api.request(IMethod.GET, url)).json();
    };

}

export default AssessmentResource;
