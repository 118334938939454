export const fetchContractManagement = async ({
  path,
  sessionKey,
  streamCode,
  method,
  body,
  headers
}: {
  path: string;
  sessionKey: string;
  streamCode?: string;
  method?: string;
  body?: BodyInit;
  headers?: HeadersInit;
}) => {
  let baseUrl = process.env.REACT_APP_CONTRACT_MANAGEMENT_API_URL;

  if(process.env.NODE_ENV === 'production') {
    baseUrl = '__REACT_APP_CONTRACT_MANAGEMENT_API_URL__';
  }

  const response = await fetch(
    `${baseUrl}${path}`,
    {
      method,
      headers: {
        ...headers,
        'X-Api-Key': sessionKey,
        ...(streamCode ? { 'X-Assume-Account': streamCode } : {})
      },
      body
    }
  );

  if(response.headers.get('content-type') === 'application/json') {
    return await response.json();
  }

  return await response.text();
};
