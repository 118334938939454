import { Action } from 'redux';

import { IParagraph } from 'types/thoughtriver';
import { IParty } from '../Parties/Parties-types';

export const SET_PARAGRAPHS = 'SET_PARAGRAPHS';
export const SET_CONTRACT_NAME = 'SET_CONTRACT_NAME';
export const SET_PARTY_OCCURRENCES = 'SET_PARTY_OCCURRENCES';

export interface IContractDispatchProps {
    setContractScrollToParty: (party: IParty) => void;
}

export interface IContractProps extends IContractStateProps, IContractDispatchProps {
    paragraphs: IParagraph[];
}

export interface IContractStateProps {
    paragraphs: IParagraph[];
    parties: IParty[];
    contractScrollToParty: IParty;
}

export interface IContractReducer extends Omit<IContractStateProps, 'contractScrollToParty' | 'parties'> {
    name: string;
}

export interface ISetParagraphsAction extends Action {
    type: typeof SET_PARAGRAPHS;
    paragraphs: IParagraph[];
}

export interface ISetContractNameAction extends Action {
    type: typeof SET_CONTRACT_NAME;
    name: string;
}

export type IContractActionTypes = ISetParagraphsAction |
ISetContractNameAction;
