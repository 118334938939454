import { capitalize } from '../components/App/App-helpers';
import { reduxStore } from '../redux/store';

import {
    IMethod,
    ERROR_UNAUTHORIZED,
    ERROR_NOT_FOUND,
    ERROR_SERVER,
    ERROR_UNKNOWN,
} from './api-types';

class Api {

    statusToErrorType(status: number) {
        let errorType;

        switch (status) {
            case 401: {
                errorType = ERROR_UNAUTHORIZED;
                break;
            }
            case 404: {
                errorType = ERROR_NOT_FOUND;
                break;
            }
            case 500: {
                errorType = ERROR_SERVER;
                break;
            }
            default: {
                errorType = ERROR_UNKNOWN;
            }
        }

        return errorType;
    }

    async request(
        method: IMethod,
        url: string,
        payload: any = null,
        authorize: boolean = true,
        baseUrl: string = null,
        noRedirect: boolean = false,
        authHeaderKey: string = 'Authorization',
    ): Promise<any> {
        let { authToken, apiUrl, serviceType } = reduxStore.store.getState().app;

        apiUrl = baseUrl !== null ? baseUrl : apiUrl;

        const options: any = {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-application': capitalize(serviceType)
            }
        };

        if (authorize) {
            options.headers[authHeaderKey] = `Bearer ${authToken}`;
        }

        if (noRedirect) {
            options.headers['X-No-Redirect'] = 'true';
        }

        if (payload) {
            switch (method) {
                case IMethod.GET:
                    options.qs = payload;
                    break;
                default:
                    options.body = JSON.stringify(payload);
            }
        }

        const response = await fetch(`${apiUrl}${url}`, options);

        if (response.ok) {
            return response;
        } else {
            throw new Error(this.statusToErrorType(response.status));
        }
    }
}

export default new Api();
