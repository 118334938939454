import { combineReducers } from 'redux';
import AppReducer from '../components/App/App-reducer';
import { IRootState } from './reducers-types';
import PartiesReducer from '../components/Parties/Parties-reducer';
import ContractReducer from '../components/Contract/Contract-reducer';
import ModalDialogReducer from '../components/ModalDialog/ModalDialog-reducer';
import { AnalyticsReducer } from '../services/Analytics-reducer';
import FeatureToggleProviderReducer from '../components/FeatureToggleProvider/FeatureToggleProvider-reducer';

const rootReducer = combineReducers<IRootState>({
    app: AppReducer,
    analyticsState: AnalyticsReducer,
    contract: ContractReducer,
    featureToggles: FeatureToggleProviderReducer,
    modalDialog: ModalDialogReducer,
    parties: PartiesReducer,
});

export default rootReducer;
