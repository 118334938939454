import {
    IAppStatus,
    IAuthDetails,
    ISetAccountCodeAction,
    ISetApiUrlAction,
    ISetAppStatusAction,
    ISetAuthDetailsAction,
    ISetAuthTokenAction,
    ISetContractIdAction,
    ISetUsernameAction,
    ISetVersionIdAction,
    SET_ACCOUNT_CODE,
    SET_API_URL,
    SET_APP_STATUS,
    SET_AUTH_DETAILS,
    SET_AUTH_TOKEN,
    SET_CONTRACT_ID,
    SET_USERNAME,
    SET_VERSION_ID,
    SET_VERSION_NUMBER,
    SET_SERVICE_EMAIL,
    ISetServiceEmailAction,
    ISetReplyToAction,
    SET_REPLY_TO,
    ISetMessageIdAction,
    SET_MESSAGE_ID,
    ISetServiceTypeAction,
    SET_SERVICE_TYPE,
    IServiceType,
    ISetVersionNumberAction,
    IUserDetails,
    ISetUserDetailsAction,
    SET_USER_DETAILS,
} from './App-types';

export function setAccountCodeAction(accountCode: string): ISetAccountCodeAction {
    return {
        type: SET_ACCOUNT_CODE,
        accountCode,
    };
}

export function setApiUrlAction(apiUrl: string): ISetApiUrlAction {
    return {
        type: SET_API_URL,
        apiUrl,
    };
}

export function setAppStatusAction(status: IAppStatus, statusMessage: string = ''): ISetAppStatusAction {
    return {
        type: SET_APP_STATUS,
        status,
        statusMessage,
    };
}

export function setAuthDetailsAction(authDetails: IAuthDetails): ISetAuthDetailsAction {
    return {
        ...authDetails,
        type: SET_AUTH_DETAILS,
    }
}

export function setAuthTokenAction(authToken: string): ISetAuthTokenAction {
    return {
        type: SET_AUTH_TOKEN,
        authToken,
    };
}

export function setContractIdAction(contractId: string): ISetContractIdAction {
    return {
        type: SET_CONTRACT_ID,
        contractId,
    };
}

export function setUsernameAction(username: string): ISetUsernameAction {
    return {
        type: SET_USERNAME,
        username,
    };
}

export function setMessageIdAction(messageId: string): ISetMessageIdAction {
    return {
        type: SET_MESSAGE_ID,
        messageId,
    };
}

export function setReplyToAction(replyTo: string): ISetReplyToAction {
    return {
        type: SET_REPLY_TO,
        replyTo,
    };
}

export function setServiceEmailAction(serviceEmail: string): ISetServiceEmailAction {
    return {
        type: SET_SERVICE_EMAIL,
        serviceEmail,
    };
}

export function setServiceTypeAction(serviceType: IServiceType): ISetServiceTypeAction {
    return {
        type: SET_SERVICE_TYPE,
        serviceType,
    };
}

export function setVersionIdAction(versionId: string): ISetVersionIdAction {
    return {
        type: SET_VERSION_ID,
        versionId,
    };
}

export function setVersionNumberAction(versionNumber: number): ISetVersionNumberAction {
    return {
        type: SET_VERSION_NUMBER,
        versionNumber,
    };
}

export function setUserDetailsAction({ firstName, lastName, email, tier, roles, id }: IUserDetails): ISetUserDetailsAction {
    return {
        type: SET_USER_DETAILS,
        firstName,
        id,
        lastName,
        email,
        tier,
        roles,
    };
}
