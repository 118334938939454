import React from 'react';
// import { Help } from '@material-ui/icons';
import Paragraph from '../Paragraph/Paragraph-container';
import { sortParties, getMatchPattern } from '../Parties/Parties-helpers';
import { IContractProps } from './Contract-types';
import { Domain } from '@material-ui/icons';

class Contract extends React.Component<IContractProps> {
    private ref: React.RefObject<any>;

    constructor(props: IContractProps) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidUpdate() {
        const { contractScrollToParty, setContractScrollToParty } = this.props;

        if (contractScrollToParty) {
            try {
                const $scrollTarget = document.querySelector(`[data-party='${contractScrollToParty.name.replaceAll("'","\\'")}']`) as HTMLElement;

                if ($scrollTarget) {
                    const offsetTop = $scrollTarget.offsetTop;
                    const targetOffset = offsetTop - this.ref.current.offsetTop - 50;

                    if (typeof this.ref.current.scrollTo === 'function') {
                        this.ref.current.scrollTo({
                            left: 0,
                            top: targetOffset,
                            behavior: 'smooth',
                        });
                    } else {
                        this.ref.current.scrollTop = targetOffset;
                    }
                }

                setContractScrollToParty(null);
            } catch (error) {
                console.log('Error scrolling to party name');
            }
        }
    }

    render() {
        const { paragraphs, parties } = this.props;

        const sortedParties = sortParties(parties);

        const partiesMatchPattern = getMatchPattern(sortedParties.map(party => party.name));

        const paragraphsList = paragraphs.map((paragraph, index) =>
            <Paragraph
                key={paragraph.id}
                parties={sortedParties}
                partiesMatchPattern={partiesMatchPattern}
                text={paragraph.attributes.content}
                index={index}
            ></Paragraph>
        );

        return (
            <div className="contract">
                <div className="caption">
                    <span className="caption-text">
                        <span>
                            These can include company names (e.g. 'Acme Inc') and references (e.g. the 'Customer'). Select the company or reference names below and drag &amp; drop them to the correct field on the right.
                            <br/>
                            You can mark a company name as the formal legal name by using the building icon <Domain className="building-icon" /> next to a selected name in the party boxes on the right.
                        </span>
                    </span>
                    {/* <span className="caption-icon">
                        <Help />
                    </span> */}
                </div>
                <div className="contract-body" ref={this.ref} id="contract-body">
                    <ul className="paragraphs">
                        {paragraphsList}
                    </ul>
                </div>
            </div>
        );
    }


}

export default Contract;
