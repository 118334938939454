export const FLOW_AUTH_HEADER_KEY = 'X-Authorization';

export interface IFlowResourceStatic {
    activateFlow: (payload: IActivateFlowPayload) => Promise<any>;
}

export interface IFlowResource  {

}

export interface IActivateFlowPayload {
    apiBaseUrl: string;
    assessmentsCount: number;
	contractId: string;
	messageId: string;
	replyTo: string;
	serviceEmail: string;
}
