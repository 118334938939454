import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { setSelectedPartyAction } from '../Party/Party-actions';
import { IParty, IPartiesType } from '../Parties/Parties-types';
import { IRootState } from '../../redux/reducers-types';
import Paragraph from './Paragraph';
import { IParagraphDispatchProps, IParagraphStateProps } from './Paragraph-types';
import { setHighlightedPartyAction, setPartyToAddAction } from './Paragraph-actions';
import { setExpandedGroupAction, setScrollToPartyAction, setDraggedPartyAction, setHighlightingAction } from '../Parties/Parties-actions';

function mapStateToProps(state: IRootState): IParagraphStateProps {
    return {
        expandedGroup: state.parties.expandedGroup,
        highlightedParty: state.parties.highlightedParty,
        highlighting: state.parties.highlighting,
        partyToAdd: state.parties.partyToAdd,
        selectedParty: state.parties.selectedParty,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IParagraphDispatchProps {
    return {
        setDraggedParty: (party: IParty) => dispatch(setDraggedPartyAction(party)),
        setExpandedGroup: (group: IPartiesType) => dispatch(setExpandedGroupAction(group)),
        setHighlightedParty: (party: IParty) => dispatch(setHighlightedPartyAction(party)),
        setHighlighting: (highlighting: boolean) => dispatch(setHighlightingAction(highlighting)),
        setPartyToAdd: (partyToAdd: string) => dispatch(setPartyToAddAction(partyToAdd)),
        setScrollToParty: (party: IParty) => dispatch(setScrollToPartyAction(party)),
        setSelectedParty: (party: IParty) => dispatch(setSelectedPartyAction(party)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Paragraph);
