import {
    IAnalyticsState,
    AnalyticsActionTypes,
    SET_INITIALISED,
} from './Analytics-types';

export const initialState: IAnalyticsState = {
    initialised: false,
};

export function AnalyticsReducer(state: IAnalyticsState = initialState, action: AnalyticsActionTypes): IAnalyticsState {
    switch (action.type) {
        case SET_INITIALISED:
            return {
                ...state,
                initialised: true,
            };
        default:
            return state;
    }
}
