import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IParty } from '../Parties/Parties-types';
import { IRootState } from '../../redux/reducers-types';
import Contract from './Contract';
import { setContractScrollToPartyAction } from '../Parties/Parties-actions';
import { IContractStateProps, IContractDispatchProps } from './Contract-types';

function mapStateToProps(state: IRootState): IContractStateProps {
    return {
        parties: state.parties.parties,
        paragraphs: state.contract.paragraphs,
        contractScrollToParty: state.parties.contractScrollToParty,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IContractDispatchProps {
    return {
        setContractScrollToParty: (party: IParty) => dispatch(setContractScrollToPartyAction(party)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
