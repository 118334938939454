import Api from '../services/api';
import { IMethod } from '../services/api-types';
import {
    IFlowResourceStatic,
    IFlowResource,
    IActivateFlowPayload,
    FLOW_AUTH_HEADER_KEY,
} from './flow-types';

const FlowResource: IFlowResourceStatic = class FlowResource implements IFlowResource {

    static async activateFlow(payload: IActivateFlowPayload): Promise<any> {
        const url = process.env.NODE_ENV === 'production' ? '__REACT_APP_FLOW_URL__' : process.env.REACT_APP_FLOW_URL;
        return await Api.request(IMethod.POST, url, payload, true, '', false, FLOW_AUTH_HEADER_KEY);
    };

}

export default FlowResource;
