import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import ModalDialog from './ModalDialog';
import {
    hideDialogAction,
    showDialogAction,
    updateAutoCloseAction,
    updateButtonsAction,
} from './ModalDialog-actions';
import {
    IModalDialogButton,
    IModalDialogDispatchProps,
    IModalDialogStateProps,
} from './ModalDialog-types';

function mapStateToProps(state: IRootState): IModalDialogStateProps {
    return state.modalDialog;
}

function mapDispatchToProps(dispatch: Dispatch): IModalDialogDispatchProps {
    return {
        showDialog: (title: string | JSX.Element,
                     showCloseButton: boolean = true,
                     component: JSX.Element,
                     buttons: IModalDialogButton[] = [],
                     height: string | null | undefined = undefined) =>
            dispatch(showDialogAction(title, showCloseButton, component, buttons, height)),
        hideDialog: () => dispatch(hideDialogAction()),
        updateButtons: (buttons: IModalDialogButton[]) => dispatch(updateButtonsAction(buttons)),
        updateAutoClose: (autoClose: boolean) => dispatch(updateAutoCloseAction(autoClose)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialog);
