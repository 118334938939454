import {
    SET_PARAGRAPHS,
    ISetParagraphsAction,
    ISetContractNameAction,
    SET_CONTRACT_NAME
} from './Contract-types';
import { IParagraph } from 'types/thoughtriver';

export function setParagraphsAction(paragraphs: IParagraph[]): ISetParagraphsAction {
    return {
        type: SET_PARAGRAPHS,
        paragraphs,
    };
}

export function setContractNameAction(name: string): ISetContractNameAction {
    return {
        type: SET_CONTRACT_NAME,
        name,
    };
}
