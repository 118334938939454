import React from 'react';
import { toast } from 'react-toastify';

import { ILoginProps, ILoginState } from './Login-types';
import AuthResource from '../../resources/auth';
import { decodeToken } from '../App/App-helpers';
import logoDark from '../../assets/logo-dark.svg';
import { IAppStatus } from '../App/App-types';

class Login extends React.Component<ILoginProps, ILoginState> {

    constructor(props: ILoginProps) {
        super(props);

        const { accountCode, username } = this.props;

        this.state = {
            accountCode: accountCode ? accountCode : '',
            username: username ? username : '',
            password: '',
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    inputNameToLabel(text: string) {
        return text.split(/([a-z]+)([A-Z][a-z]+)/)
            .filter(word => word)
            .map(word => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`)
            .join(' ');
    }

    onInputChange({ target }: any) {
        const state = {...this.state};
        state[target.name] = target.value;
        this.setState(state);
    }

    async onSubmit(event: any) {
        event.preventDefault();

        const { accountCode, username, password } = this.state;

        if (!accountCode || !username || !password) {
            toast.info('Please fill out all the fields', {
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        };

        try {
            const authResponse = await AuthResource.login(accountCode, username, password);
            await this.props.setAuthDetails(decodeToken(authResponse.access_token));
            await this.props.setAppStatus(IAppStatus.LOADING);
            await this.props.onSuccessCallback(this.props.contractId);
        } catch (error) {
            toast.error('Incorrect account code, username, or password.', {
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    }

    renderInput(name: string, type: string = 'text') {
        return (
            <div className="form-control-wrapper">
                <label className="control-label" htmlFor={name}>{this.inputNameToLabel(name)}</label>
                <input
                    className="form-control"
                    name={name}
                    placeholder={this.inputNameToLabel(name)}
                    autoComplete="off"
                    value={this.state[name]}
                    onChange={this.onInputChange}
                    type={type}
                />
            </div>
        );
    }

    render() {
        return (
            <form className="login-form" onSubmit={this.onSubmit}>
                <div className="logo">
                    <img className="logo-img" src={logoDark} alt="ThoughtRiver Logo" />
                </div>
                <div className="message">
                    The link has expired. Please login to view the contract.
                </div>
                {this.renderInput('accountCode')}
                {this.renderInput('username')}
                {this.renderInput('password', 'password')}
                <button className="btn btn-primary btn-block" type="submit">Login</button>
            </form>
        );
    }
}

export default Login;
