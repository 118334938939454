import { IAnalyticsEnv, MP_SHARED } from './Analytics-types';

import { IAppReducer, IAuthDetails } from '../components/App/App-types';
import MixpanelHelper from './MixpanelHelper';
import { capitalize, decodeToken } from '../../src/components/App/App-helpers';
import { reduxStore } from '../redux/store';

const mixpanel = require('mixpanel-browser');
const moment = require('moment');
const get = require('lodash.get');

class AnalyticsService {
  private mpSharedTrackingId: string;
  private initialised: boolean;

  constructor() {
    this.mpSharedTrackingId = this.getAnalyticsEnv().mpSharedTrackingId;
    this.initialised = false;
  }

  getAccountId(authDetails: IAuthDetails) {
    const isOptedOut = get(authDetails, 'analyticsOptOut', true);
    let accountCode = authDetails.accountCode?.split('/')[0];

    if (isOptedOut) {
      accountCode = get(authDetails, 'accountId', moment().valueOf());
    }

    return accountCode;
  }

  getAnalyticsEnv(): IAnalyticsEnv {
    const { REACT_APP_MP_SHARED_TRACKING_ID: mpSharedTrackingId } = process.env;

    if (process.env.NODE_ENV === 'production') {
      return {
        mpSharedTrackingId: '__REACT_APP_MP_SHARED_TRACKING_ID__',
      };
    }

    return {
      mpSharedTrackingId,
    };
  }

  getUserId(authDetails: IAuthDetails) {
    return get(authDetails, 'userId', moment().valueOf());
  }

  getCompanyId(authDetails: IAuthDetails) {
    const isOptedOut = get(authDetails, 'analyticsOptOut', true);
    let email = get(authDetails, 'username');
    let companyId = this.extractEmailDomain(email);

    if (isOptedOut) {
      companyId = 'external';
    }

    return companyId;
  }

  extractEmailDomain(email: string): string {
    const parts = email ? email.match(/@([^.]+)/) : [];
    return parts[1] ? String(parts[1]).toLowerCase() : 'unknown';
  }

  getCurrentUrl() {
    return window.location.origin;
  }

  /**
   * Initialises the analytics service
   */
  async initialise(authDetails: IAuthDetails): Promise<void> {
    if (!authDetails) {
      return;
    }

    if (this.mpSharedTrackingId) {
      await this.initialiseMixpanel(
        MP_SHARED,
        this.mpSharedTrackingId,
        authDetails
      );
    }

    this.setInitialised();
  }

  async initialiseMixpanel(
    project: string,
    trackingId: string,
    auth: IAuthDetails,
    region: string = ''
  ) {
    const userId = this.getUserId(auth);
    const { user } = reduxStore.store.getState().app as IAppReducer;

    await mixpanel.init(
      trackingId,
      {
        debug: process.env.NODE_ENV !== 'production',
        ip: false,
        property_blacklist: [
          '$current_url',
          '$referrer',
          '$initial_referrer',
          'mp_page',
        ],
        track_pageview: false,
      },
      project
    );

    if (mixpanel[project]) {
      await mixpanel[project].identify(userId);

      if (mixpanel[project].people) {
        await mixpanel[project].people.set({
          'User ID': userId,
          Company: this.getCompanyId(auth),
          Account: this.getAccountId(auth),
          Region: region,
          $email: user.email,
          $first_name: user.firstName,
          $last_name: user.lastName,
          'User Roles': user.roles,
          'Subscription Tier': user.tier,
        });
      }
    }
  }

  /**
   * Submits an event to the analytics service
   *
   * @param category - A top level category for the event (e.g. 'User', 'Navigation')
   * @param action - A description of the behaviour (e.g. 'Clicked Delete', 'Added a component', 'Deleted account')
   * @param label - More precise labelling of the related action. (e.g. for 'Clicked Delete' we can add
   *                the entity being deleted - 'Property', 'Theme', etc.)
   * @param payload - an object with additional data passed to the event
   *
   */
  recordEvent(_: string, action: string, label?: string, payload: any = {}) {
    const app = reduxStore.store.getState().app as IAppReducer;
    const serviceType = capitalize(app.serviceType);
    let auth;
    try {
      auth = decodeToken(app.authToken);
    } catch {
      //
    }

    if (!this.initialised) {
      return;
    }

    const currentUrl = this.getCurrentUrl();

    const mpEventPayload = {
      Action: action,
      Label: label,
      UserId: this.getUserId(auth),
      Application: serviceType,
      $current_url: currentUrl,
      $referrer: currentUrl,
      $initial_referrer: currentUrl,
      mp_page: currentUrl,
      'Subscription Tier': app.user.tier,
      ...payload,
    };

    if (this.mpSharedTrackingId && mixpanel[MP_SHARED]) {
      mixpanel[MP_SHARED].track(action, mpEventPayload);
      const mixpanelHelper = new MixpanelHelper(MP_SHARED);
      mixpanelHelper.incrementProperty(action);
      mixpanelHelper.registerFirstProperty(action);
      mixpanelHelper.registerLastProperty(action);
    }
  }

  setInitialised() {
    this.initialised = true;
  }
}

export let analyticsService = new AnalyticsService();
