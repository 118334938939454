import React from 'react';
import flagsmith, {
    IFlags,
} from 'flagsmith';

import {
    FEATURE_TOGGLES_POLLING_TIME_MS,
    IFeatureToggle,
    IFeatureToggleProviderProps,
    IFeatureToggleProviderState,
} from './FeatureToggleProvider-types';

class FeatureToggleProvider extends React.Component<IFeatureToggleProviderProps, IFeatureToggleProviderState> {

    constructor(props: IFeatureToggleProviderProps) {
        super(props);

        this.handleToggleChange = this.handleToggleChange.bind(this);
    }

    async componentDidMount(): Promise<void> {
        let featureToggleApiKey = process.env.REACT_APP_FEATURE_TOGGLE_API_KEY;

        if (process.env.NODE_ENV === 'production') {
            featureToggleApiKey = '__REACT_APP_FEATURE_TOGGLE_API_KEY__';
        }

        await flagsmith.init({
            environmentID: featureToggleApiKey ?? '',
            cacheFlags: true,
            preventFetch: false,
            onChange: this.handleToggleChange,
        });
        await flagsmith.identify(this.props.userId);
        flagsmith.startListening(FEATURE_TOGGLES_POLLING_TIME_MS);
    }

    componentWillUnmount(): void {
        flagsmith.stopListening();
    }

    private parseToggleValue(value: string | number | boolean): string | number | boolean | object {
        let parsedValue = value;

        try {
            parsedValue = JSON.parse(String(value));
        } catch {
            // do nothing
        }

        return parsedValue;
    }

    async handleToggleChange(flags: IFlags) {
        try {
            const toggles: IFeatureToggle[] = [];

            if (flags && typeof flags === 'object') {
                for (const [feature, flag] of Object.entries(flags)) {
                    toggles.push({
                        feature,
                        enabled: flag.enabled,
                        value: this.parseToggleValue(flag.value),
                    });
                }

                if (JSON.stringify(this.props.featureToggles) !== JSON.stringify(toggles)) {
                    this.props.setFeatureToggles(toggles);
                }
            }
        } catch {
            console.log('Error retrieving feature toggles');
        }
    }

    render() {
        return this.props.children;
    }
}

export default FeatureToggleProvider;
