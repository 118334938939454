import { analyticsService } from '../../services/Analytics';
import {
    ACTION_SET_PARTIES_CONFIRMATION_CONTINUE,
    ACTION_SPECIFY_PARTIES,
    CATEGORY_USER,
} from '../../services/Analytics-types';
import { ISidebarProps } from './Sidebar-types';

export function analytics() {
    return (target: any) => {
        const onSubmit = target.prototype.onSubmit;
        const onDialogueContinue = target.prototype.onDialogueContinue;

        target.prototype.onSubmit = function () {
            const {
                contractId,
                streamCode,
                versionId,
                versionNumber
            } = this.props as ISidebarProps;

            analyticsService.recordEvent(
                CATEGORY_USER,
                ACTION_SPECIFY_PARTIES,
                '',
                {
                    ContractId: contractId,
                    Stream: streamCode,
                    VersionId: versionId,
                    VersionNumber: versionNumber,
                }
            );

            onSubmit.apply(this, arguments);
        };

        target.prototype.onDialogueContinue = function () {
            const {
                contractId,
                streamCode,
                versionId,
                versionNumber
            } = this.props as ISidebarProps;

            analyticsService.recordEvent(
                CATEGORY_USER,
                ACTION_SET_PARTIES_CONFIRMATION_CONTINUE,
                '',
                {
                    ContractId: contractId,
                    Stream: streamCode,
                    VersionId: versionId,
                    VersionNumber: versionNumber
                }
            );

            onDialogueContinue.apply(this, arguments);
        };
    };
}
