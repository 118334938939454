import { IAppReducer } from '../components/App/App-types';
import { reduxStore } from '../redux/store';
import Api from '../services/api';
import { IMethod } from '../services/api-types';
import { fetchContractManagement } from './contract-management';
import {
  IVersionResourceStatic,
  IVersionResource,
  IPartiesPayload,
} from './version-types';
import { IPartyGroups } from 'types/thoughtriver';

const VersionResource: IVersionResourceStatic = class VersionResource
  implements IVersionResource
{
  static async getVersion(contractId: string, versionId: string): Promise<any> {
    const { sessionKey, stream } = reduxStore.store.getState()
      .app as IAppReducer;

    if (sessionKey) {
      return fetchContractManagement({
        path: `/v0_1/contracts/${contractId}/versions/${versionId}`,
        sessionKey,
        streamCode: stream,
      });
    } else {
      return await (
        await Api.request(
          IMethod.GET,
          `/contracts/${contractId}/versions/${versionId}`
        )
      ).json();
    }
  }

  static async patchVersion(
    contractId: string,
    versionId: string,
    parties: IPartyGroups,
    accountCode: string
  ): Promise<any> {
    const payload: IPartiesPayload = {
      data: {
        attributes: {
          parties,
        },
      },
    };

    const { sessionKey, stream } = reduxStore.store.getState().app as IAppReducer;

    if (sessionKey) {
      return fetchContractManagement({
        path: `/v0_2/contracts/${contractId}/versions/${versionId}`,
        sessionKey,
        streamCode: stream,
        method: IMethod.PATCH,
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } else {
      return await Api.request(
        IMethod.PATCH,
        `/v0_1/contracts/${contractId}/versions/${versionId}?tenant_code=${accountCode}`,
        payload,
        true,
        process.env.NODE_ENV === 'production' ? '__REACT_APP_CONTRACT_MANAGEMENT_API_URL__' : process.env.REACT_APP_CONTRACT_MANAGEMENT_API_URL,
        true
      );
    }
  }
};

export default VersionResource;
