import React from 'react';

import {
    IModalDialogButton,
    IModalDialogProps,
    IModalDialogState,
    ModalDialogButtonType,
} from './ModalDialog-types';
import { Cancel } from '@material-ui/icons';
import ModalOverlay from '../ModalOverlay/ModalOverlay';

class ModalDialog extends React.Component<IModalDialogProps, IModalDialogState> {

    constructor(props: IModalDialogProps) {
        super(props);

        this.hide = this.hide.bind(this);
    }

    private hide() {
        if (this.props.autoClose) {
            this.props.hideDialog();
        }
    }

    private buttonClick(button: IModalDialogButton) {
        if (button.callback !== undefined) {
            button.callback(button);
        }

        if (button.type === ModalDialogButtonType.Negative) {
            this.hide();
        }
    }

    private renderCloseButton() {
        return this.props.showCloseButton && this.props.autoClose
            ? (
                <div className="modal-dialog-close-button"
                     onClick={this.hide}>
                    <Cancel/>
                </div>
            )
            : undefined;
    }

    private renderButtonBar() {
        return this.props.buttons.length
            ? (
                <div className="modal-dialog-window-footer">
                    {this.props.buttons.map((button, index) =>
                        <button key={index}
                                disabled={!button.enabled}
                                className={this.getButtonClass(button)}
                                onClick={() => this.buttonClick(button)}>
                            {button.label}
                        </button>,
                    )}
                </div>
            )
            : undefined;
    }

    private renderComponent() {
        return this.props.component instanceof Object
            ? this.props.component
            : undefined;
    }

    private getButtonClass(button: IModalDialogButton) {
        const classList = ['btn'];

        switch (button.type) {
            case ModalDialogButtonType.Positive:
                classList.push('btn-primary');
                break;

            case ModalDialogButtonType.Neutral:
                classList.push('btn-secondary');
                break;

            case ModalDialogButtonType.Negative:
                classList.push('flat');
                break;
        }

        return classList.join(' ');
    }

    private stopEvent(event: React.MouseEvent) {
        event.stopPropagation();
    }

    private getCSSValue(property: string, value: string | null | undefined) {
        return typeof value === 'string' ? { [property]: value } : {};
    }

    render() {
        const { title, visible, height } = this.props;

        return (
            <ModalOverlay
                className="modal-dialog"
                visible={visible}
                onClick={this.hide}
            >
                <div
                    className="modal-dialog-window"
                    style={{ ...this.getCSSValue('maxHeight', height) }}
                    onClick={this.stopEvent}
                >

                    {title ?
                        <div className="modal-dialog-window-header">
                            <div className="modal-dialog-title">
                                {title}
                            </div>
                            {this.renderCloseButton()}
                        </div> : null
                    }

                    <div className="modal-dialog-window-content">
                        {this.renderComponent()}
                    </div>

                    {this.renderButtonBar()}
                </div>
            </ModalOverlay>
        );
    }
}

export default ModalDialog;
