import {
    IContractActionTypes,
    SET_PARAGRAPHS,
    ISetParagraphsAction,
    IContractReducer,
    SET_CONTRACT_NAME,
    ISetContractNameAction,
} from './Contract-types';

const initialState: IContractReducer = {
    name: '',
    paragraphs: [],
};

const ContractReducer =
    (state = initialState, action: IContractActionTypes): IContractReducer => {
        switch (action.type) {
            case SET_PARAGRAPHS:
                return {
                    ...state,
                    paragraphs: (action as ISetParagraphsAction).paragraphs,
                };
            case SET_CONTRACT_NAME:
                return {
                    ...state,
                    name: (action as ISetContractNameAction).name,
                };
            default:
                return state;
        }
    };

export default ContractReducer;
