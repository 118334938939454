export function getRelationship(resource: any, relationship: string) {
    return resource.data.relationships[relationship].data;
}

export function getAttribute(resource: any, attribute: string) {
    return resource.data.attributes[attribute];
}

export function getLatestRelationshipId(items: any) {
    return [...items].pop()['id'];
}

