import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {  IAuthDetails, IAppStatus } from '../App/App-types';

import { setAuthDetailsAction, setAppStatusAction } from '../App/App-actions';
import { IRootState } from '../../redux/reducers-types';
import { ILoginStateProps, ILoginDispatchProps } from './Login-types';
import Login from './Login';

function mapStateToProps(state: IRootState): ILoginStateProps {
    return {
        accountCode: state.app.accountCode,
        contractId: state.app.contractId,
        username: state.app.username,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): ILoginDispatchProps {
    return {
        setAppStatus: (status: IAppStatus, statusMessage?: string) =>
            dispatch(setAppStatusAction(status, statusMessage)),
        setAuthDetails: (authDetails: IAuthDetails) => dispatch(setAuthDetailsAction(authDetails)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
