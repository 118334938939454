import { Domain } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { toast } from 'react-toastify';

import Parties from '../Parties/Parties-container';
import { IPartiesType, IParty } from '../Parties/Parties-types';
import { ISidebarProps, ISidebarState } from './Sidebar-types';
import { mapOverridesToParties } from '../Parties/Parties-helpers';
import VersionResource from '../../resources/version';
import { IAppStatus, IServiceType } from '../App/App-types';
import FlowResource from '../../resources/flow';
import AssessmentResource from '../../resources/assessment';
import { tokenIsValid } from '../App/App-helpers';
import { IActivateFlowPayload } from '../../resources/flow-types';
import { ModalDialogButtonType } from '../ModalDialog/ModalDialog-types';
import { analytics } from './Sidebar-analytics';

const { OWN_PARTIES, COUNTER_PARTIES, RECIPROCAL, DISCARDED } = IPartiesType;

type TitleType<T> = T extends 'title'
  ? string
  : T extends 'element'
  ? JSX.Element
  : never;

@analytics()
class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
      submitting: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  getTitle<T extends 'title' | 'element'>(
    partyType: IPartiesType,
    titleType: T
  ): TitleType<T> {
    let titles: { title?: string; element?: ReactElement } = {};

    switch (partyType) {
      case OWN_PARTIES:
        titles = {
          title: 'Own Party',
          element: (
            <span>
              Names used to refer to your company. Select the option that
              denotes your company’s legal name and click the building icon{' '}
              <Domain className="building-icon" /> to mark it as the company
              formal name.
            </span>
          ),
        };
        break;
      case COUNTER_PARTIES:
        titles = {
          title: 'Counter Party',
          element: (
            <span>
              Names used to refer to ‘them'. Select the option that denotes the
              company’s legal name and click the building icon{' '}
              <Domain className="building-icon" /> to mark it as the company
              formal name. This name will display on the negotiation dashboard
              in the Counterparty column.
            </span>
          ),
        };
        break;
      case RECIPROCAL:
        titles = {
          title: 'Reciprocal',
          element: <span>Names used to refer to both 'us' and 'them'</span>,
        };
        break;
      case DISCARDED:
        titles = {
          title: 'Discarded',
          element: <span>Names not considered to be party names</span>,
        };
        break;
      default:
        throw new Error('Invalid party type');
    }

    return titles[titleType] as TitleType<T>;
  }

  validateForm(parties: IParty[]): string[] {
    const errors: string[] = [];

    if (
      !parties.filter((party) => party.type === IPartiesType.OWN_PARTIES).length
    ) {
      errors.push('Own party');
    }

    if (
      !parties.filter((party) => party.type === IPartiesType.COUNTER_PARTIES)
        .length
    ) {
      errors.push('Counter party');
    }

    return errors;
  }

  private onDialogueContinue() {
    this.updateParties();
    this.props.hideDialog();
  }

  private openConfirmationDialogue() {
    const { showDialog } = this.props;

    showDialog(
      'Set Party Confirmation',
      true,
      <p>
        One or more parties is missing. Do you want to continue with the review?
      </p>,
      [
        {
          label: 'Cancel',
          type: ModalDialogButtonType.Negative,
          enabled: true,
          callback: () => this.props.hideDialog(),
        },
        {
          label: 'Continue',
          type: ModalDialogButtonType.Positive,
          enabled: true,
          callback: () => this.onDialogueContinue(),
        },
      ],
      null
    );
  }

  async onSubmit() {
    const { parties } = this.props;
    const formErrors = this.validateForm(parties);

    if (formErrors.length) {
      this.openConfirmationDialogue();
      return;
    }

    await this.updateParties();
  }

  async updateParties() {
    const {
      accountCode,
      contractId,
      parties,
      versionId,
      setAppStatus,
      apiBaseUrl,
      messageId,
      replyTo,
      serviceEmail,
      serviceType,
      tokenExp,
    } = this.props;
    const partiesPayload = mapOverridesToParties(parties);

    try {
      this.setState({
        submitting: true,
      });

      if (tokenExp && !tokenIsValid(tokenExp, 5)) {
        const statusMessage = 'The provided API access token has expired.';
        setAppStatus(IAppStatus.LOGIN_REQUIRED, statusMessage);
        this.setState({
          submitting: false,
        });
        return;
      }

      await VersionResource.patchVersion(
        contractId,
        versionId,
        partiesPayload,
        accountCode
      );

      if (serviceType === IServiceType.FLOW) {
        const assessments = await AssessmentResource.getAssessments(
          contractId,
          versionId
        );
        const assessmentsCount = assessments.data.length;

        const flowPayload: IActivateFlowPayload = {
          apiBaseUrl,
          assessmentsCount,
          contractId,
          messageId,
          replyTo,
          serviceEmail,
        };

        await FlowResource.activateFlow(flowPayload);
      }

      this.setState({
        submitting: false,
      });

      setAppStatus(IAppStatus.COMPLETED);

      if (serviceType === IServiceType.WORD && Office) {
        try {
          Office.context.ui.messageParent('complete', {
            targetOrigin: '*',
          });
        } catch {
          console.warn('Error communicating with Word');
        }
      }
    } catch (error) {
      this.setState({
        submitting: false,
      });
      toast.error('Updating parties has failed ', {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return;
    }
  }

  render() {
    const { expandedGroup } = this.props;
    const { getTitle } = this;
    const { submitting } = this.state;

    return (
      <div className="sidebar">
        <div className="caption">
          {submitting ? (
            <div className="loader-wrapper">
              <span className="loader"></span>
              <span>Submitting...</span>
            </div>
          ) : (
            <div>
              <span className="caption-text">All good?</span>
              <button
                className="btn btn-primary"
                onClick={this.onSubmit}
              >
                Complete Review
              </button>
            </div>
          )}
        </div>
        <div className="sidebar-content">
          <Parties
            type={OWN_PARTIES}
            title={getTitle(OWN_PARTIES, 'title')}
            subtitle={getTitle(OWN_PARTIES, 'element')}
            flipWith={COUNTER_PARTIES}
            nextType={COUNTER_PARTIES}
            discardTo={DISCARDED}
            overlay={true}
          />
          <Parties
            type={COUNTER_PARTIES}
            title={getTitle(COUNTER_PARTIES, 'title')}
            subtitle={getTitle(COUNTER_PARTIES, 'element')}
            flipWith={OWN_PARTIES}
            prevType={OWN_PARTIES}
            nextType={RECIPROCAL}
            discardTo={DISCARDED}
            overlay={true}
          />
          <Parties
            type={RECIPROCAL}
            title={getTitle(RECIPROCAL, 'title')}
            subtitle={getTitle(RECIPROCAL, 'element')}
            prevType={COUNTER_PARTIES}
            nextType={DISCARDED}
            discardTo={DISCARDED}
            overlay={true}
            compact={true}
          />
          <Parties
            type={DISCARDED}
            title={getTitle(DISCARDED, 'title')}
            subtitle={getTitle(DISCARDED, 'element')}
            collapsible={true}
            prevType={RECIPROCAL}
            restoreByDefault={true}
            discardTo={DISCARDED}
          />
          {expandedGroup === DISCARDED ? (
            <div className="parties-placeholder"></div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Sidebar;
