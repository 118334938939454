import 'promise-polyfill/src/polyfill';
import 'url-search-params-polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './styles/index.scss';
import App from './components/App/App-container';
import * as serviceWorker from './serviceWorker';
import { reduxStore } from './redux/store';
import FeatureToggleProvider from './components/FeatureToggleProvider/FeatureToggleProvider-container';

export const { store, persistor } = reduxStore;

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <FeatureToggleProvider>
                <App />
            </FeatureToggleProvider>
        </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
